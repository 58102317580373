<template>
  <div class="d-flex align-items-center min-vh-100 login-container">
    <b-container>
      <b-row class="justify-content-center">
        <b-col md="9" lg="6" class="login">
          <b-card-group>
            <b-card class="p-4 shadow-lg">
              <b-card-body class="login-box">
                <b-form>
                  <div class="text-center">
                    <!-- <img src="/logo.png" alt /> -->
                    <div
                      class="imgLogo"
                      v-bind:style="{
                        'background-image': 'url(' + imgLogo + ')'
                      }"
                    ></div>
                    <hr class="my-4" />
                  </div>
                  <p class="f-medium f-20 font-weight-bold text-center ">
                    ตั้งค่ารหัสผ่านใหม่
                  </p>
                  <InputText
                    class="my-3 reset-password-input"
                    v-model="form.password"
                    textFloat="รหัสผ่าน"
                    placeholder="รหัสผ่าน"
                    type="password"
                    name="password"
                    isRequired
                    :isValidate="$v.form.password.$error"
                    :v="$v.form.password"
                    @onKeyup="submitFormOnInput"
                    isShowPassword
                  />
                  <InputText
                    class="my-3 reset-password-input"
                    v-model="form.confirmPassword"
                    textFloat="ยืนรหัสผ่าน"
                    placeholder="ยืนรหัสผ่าน"
                    type="password"
                    name="password"
                    isRequired
                    :isValidate="$v.form.confirmPassword.$error"
                    :v="$v.form.confirmPassword"
                    @onKeyup="submitFormOnInput"
                    isShowPassword
                  />

                  <b-row>
                    <b-col>
                      <b-button
                        type="button"
                        class="px-4 login-btn"
                        @click="checkForm"
                        :disabled="isDisable"
                        >ยืนยัน
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </b-container>

    <ModalComingSoon ref="modalComingSoon" />
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import { required, minLength, sameAs, helpers } from "vuelidate/lib/validators";
const password = helpers.regex(
  "password",
  /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9!-_]+)$/
);
import InputText from "@/components/inputs/InputText";
import ModalComingSoon from "@/components/modal/alert/ModalComingSoon";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";

export default {
  name: "ResetPassword",
  components: {
    InputText,
    ModalComingSoon,
    ModalAlert,
    ModalAlertError,
    ModalLoading
  },
  data() {
    return {
      imgLogo: "",
      error: "",
      form: {
        code: this.$route.params.code,
        password: "",
        confirmPassword: ""
      },
      isLoading: false,
      isDisable: false,
      isLogin: false,
      passwordType: "",
      modalMessage: ""
    };
  },
  validations() {
    return {
      form: {
        password: {
          required,
          password,
          minLength: minLength(8)
        },
        confirmPassword: {
          required,
          password,
          minLength: minLength(8),
          sameAsPassword: sameAs("password")
        }
      }
    };
  },
  mounted: async function() {
    await this.getLogo();
  },
  created() {
    // setTimeout(() => {
    //   this.$nextTick(() => {
    //     this.$refs.modalComingSoon.show();
    //   });
    // }, 500);
  },
  methods: {
    getLogo: async function() {
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/setting/getLogo`,
        null,
        this.$headers,
        null
      );
      this.imgLogo = resData.detail;
    },
    checkForm: async function() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      this.submit();
    },
    submit: async function() {
      this.isDisable = true;
      this.$refs.modalLoading.show();

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/resetPassword`,
        null,
        this.$headers,
        this.form
      );

      this.isDisable = false;
      this.$refs.modalLoading.hide();
      if (data.result == 1) {
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
          window.location.href = "/";
        }, 3000);
      } else {
        if (data.detail !== null) {
          this.modalMessage = data.detail[0];
        } else {
          this.modalMessage = data.message;
        }
        this.$refs.modalAlertError.show();
      }
    },
    submitFormOnInput: function(e) {
      if (e.keyCode === 13) {
        this.checkForm();
      }
    },
    handleShowHidePassword() {
      if (this.passwordType == "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    }
  }
};
</script>

<style scoped>
.w-1rem {
  width: 1rem;
  height: 1rem;
}

.button-eye {
  position: absolute;
  right: 10px;
  top: 0.8rem;
  z-index: 6;
}

::v-deep .hastextFloat {
    top: 2.6rem;
}
</style>
